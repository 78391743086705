import { useState, useEffect } from "react";
import LeftSection from "../components/sections/LeftSection";
import RightSection from "../components/sections/RightSection";
import ChatL from "../components/ChatL";
import moment from "moment";
import ConversationControls from "../components/layouts/ConversationControls";
import ChatForm from "../components/sections/ChatForm";

const ChatPage = ({ darkToggle }) => {
  const [formData, setFormData] = useState({ input: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [conversationId, setConversationId] = useState(0);
  const [selectedResponse, setSelectedResponse] = useState("");

  const [chatLog, setChatLog] = useState([]);
  const [toggleOne, setToggleOne] = useState(true);
  const [toggleTwo, setToggleTwo] = useState(true);
  const [toggleThree, setToggleThree] = useState(true);
  const [errorSyntax, setErrorSyntax] = useState({});

  const newConversation = () => {
    window.location.href = "/";
  };

  const newConversationId = () => {
    setConversationId(moment().format("x"));
  };

  useEffect(() => {
    newConversationId();
  }, []);

  return (
    <>
      <main className="mx-auto md:px-[40px] px-[12px]">
        <div className="flex flex-col lg:flex-row" style={{ flexWrap: "wrap" }}>
          <LeftSection darkToggle={darkToggle} />
          <section className="flex flex-col justify-between lg:w-3/5 w-full h-full">
            <div className="flex flex-col h-full">
              <ConversationControls
                darkToggle={darkToggle}
                newConversation={newConversation}
                toggleOne={toggleOne}
                toggleTwo={toggleTwo}
                toggleThree={toggleThree}
                setToggleOne={setToggleOne}
                setToggleTwo={setToggleTwo}
                setToggleThree={setToggleThree}
              />
              <div className="px-[20px] py-4 mx-[40px] mt-4 bg-white rounded-[10px] dark:bg-gray-900">
                <div className="flex mb-6 items-center">
                  <img
                    className="w-[46px] h-[46px] mr-4 rounded-[4px]"
                    src={`/assets/images/${
                      darkToggle ? "darkBorder.png" : "lightBorder.png"
                    }`}
                    alt="user pic"
                  />
                  <div className="flex flex-col">
                    <h4 className="2xl:text-[18px] text-md leading-[1rem] dark:text-white mb-[2px]">
                      Chatting with Zuro
                    </h4>
                    <p className="2xl:text-[0.875rem] h-[10px] text-xs text-[#6A6A6D] leading-[1rem] dark:text-gray-700">
                      <span>{isLoading ? "Zuro is typing ..." : "Online"}</span>
                    </p>
                  </div>
                </div>
                <ChatL
                  isLoading={isLoading}
                  darkToggle={darkToggle}
                  errorSyntax={errorSyntax}
                  setErrorSyntax={setErrorSyntax}
                  chatLog={chatLog}
                  selectedResponse={selectedResponse}
                />
                <ChatForm
                  toggleOne={toggleOne}
                  toggleTwo={toggleTwo}
                  toggleThree={toggleThree}
                  setToggleThree={setToggleThree}
                  errorSyntax={errorSyntax}
                  setErrorSyntax={setErrorSyntax}
                  conversationId={conversationId}
                  setIsLoading={setIsLoading}
                  setChatLog={setChatLog}
                  formData={formData}
                  setFormData={setFormData}
                  setSelectedResponse={setSelectedResponse}
                />
              </div>
            </div>
          </section>
          <RightSection
            formData={formData}
            setFormData={setFormData}
            darkToggle={darkToggle}
          />
        </div>
      </main>
    </>
  );
};
export default ChatPage;
