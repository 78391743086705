import { useEffect, useRef } from "react";
import HighchartComponent from "./charts/HighchartComponent";
import MermaidComponent from "./charts/MermaidComponent";
import SourcesComponent from "./charts/SourcesComponent";
import ErrorsComponent from "./charts/ErrorsComponent";

const ChatL = ({
  isLoading,
  errorSyntax,
  setErrorSyntax,
  darkToggle,
  chatLog,
  selectedResponse,
}) => {
  const chatLogContainerRef = useRef(null);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    chatLogContainerRef.current.scrollTop =
      chatLogContainerRef.current.scrollHeight;
  }, [chatLog, isLoading]);

  return (
    <div
      className="chat-container overflow-y-auto flex-grow"
      ref={chatLogContainerRef}
    >
      {chatLog.map((data, index) => {
        return (
          <div key={index}>
            {data.role === "user" ? (
              <div className="flex items-end mt-4">
                <span>
                  {darkToggle ? (
                    <img
                      className="w-[46px] h-[46px] rounded-[4px]"
                      src="/assets/images/user.png"
                      alt="user"
                    />
                  ) : (
                    <img
                      className="w-[46px] h-[46px] rounded-[4px]"
                      src="/assets/images/whiteUser.png"
                      alt="user"
                    />
                  )}
                </span>
                <div className="rounded-tl-lg rounded-tr-lg rounded-br-lg max-w-xl bg-[#EDEFF1] ml-[12px] dark:bg-[#252F46]">
                  <p className="font-poppins 2xl:text-[15px] text-black text-sm p-4 dark:text-white">
                    {data.content}
                  </p>
                </div>
              </div>
            ) : (
              <div>
                {data.content &&
                  !data.errorEl &&
                  !data.highchartCode &&
                  !data.mermaidCode && (
                    <div className="flex items-end justify-end mt-4">
                      <div className="rounded-tl-lg rounded-tr-lg rounded-bl-lg max-w-xl bgGradientChat">
                        <pre
                          style={{
                            whiteSpace: "pre-wrap",
                            fontFamily: "Poppins",
                          }}
                          className="2xl:text-[15px] text-sm font-normal text-white p-4"
                        >
                          {data.content}
                        </pre>
                      </div>
                      <img
                        className="w-[46px] h-[46px] rounded-lg ml-[12px] mr-[8px]"
                        src={`/assets/images/${
                          darkToggle ? "darkBorder" : "lightBorder"
                        }.png`}
                        alt="worker"
                      />
                    </div>
                  )}

                <ErrorsComponent
                  errorSyntax={errorSyntax}
                  data={data}
                  darkToggle={darkToggle}
                />

                <MermaidComponent
                  mermaidCode={data.mermaidCode}
                  mermaidError={data.mermaidError}
                  darkToggle={darkToggle}
                  mermaidId={data.mermaidId}
                  chatLog={chatLog}
                />

                {data.highchartCode && !errorSyntax[data.highchartId] && (
                  <HighchartComponent
                    index={index}
                    data={data.highchartId}
                    darkToggle={darkToggle}
                    setErrorSyntax={setErrorSyntax}
                    chatLog={chatLog}
                  />
                )}

                <SourcesComponent
                  data={data}
                  darkToggle={darkToggle}
                  selectedResponse={selectedResponse}
                />

                {data.imgUrl && (
                  <div className="flex items-end justify-end mt-4">
                    <div className="rounded-tl-lg rounded-tr-lg rounded-bl-lg max-w-xl bgGradientChat">
                      <img
                        src={data.imgUrl}
                        alt="generated art"
                        className="w-full"
                      />
                    </div>
                    <img
                      className="w-[46px] h-[46px] rounded-lg ml-[12px] mr-[8px]"
                      src={`/assets/images/${
                        darkToggle ? "darkBorder" : "lightBorder"
                      }.png`}
                      alt="worker"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })}
      <div ref={messagesEndRef} />
    </div>
  );
};
export default ChatL;
