import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";


import Header from "./components/layouts/Header";
import ChatPage from "./pages/ChatPage";
import packageJson from '../package.json';
import { useEffect, useState } from "react";

function App() {
  const [darkToggle, setDarkToggle] = useState(false)
  useEffect(() => {
    if (darkToggle) {
      document.body.classList.add('dark');
      document.body.style.backgroundColor = '#090B16'; // dark background color
    } else {
      document.body.classList.remove('dark');
      document.body.style.backgroundColor = '#F5F5F8'; // light background color
    }
  }, [darkToggle]);
  useEffect(() => {
    const checkAndUpdateCache = () => {
      let version = localStorage.getItem('version-zuro');
      if (version !== packageJson.version) {
        if ('caches' in window) {
          caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach((name) => {
              caches.delete(name);
            });
          });
          window.location.reload(true);
        }
        localStorage.clear();
        localStorage.setItem('version-zuro', packageJson.version);
      }
    };

    checkAndUpdateCache();
  }, []);

  return (
    <>
      <Router>
        <div className="">
          <div className={`bg-[#F5F5F8] dark:bg-[#090B16] font-poppins h-screen overflow-y-hidden`}>
              <Header darkToggle={darkToggle} setDarkToggle={setDarkToggle} />
              <Routes>
                <Route path="/" element={<ChatPage darkToggle={darkToggle} setDarkToggle={setDarkToggle} />} />
                <Route path="/*" element={<Navigate to="/"  replace/>}  />
              </Routes>
          </div>
        </div>
      </Router>
    </>

  );
}

export default App;
