import { useAuth0 } from "@auth0/auth0-react";
import LoadingEl from "../Loading";

const LoginBtn = () => {
  const { isLoading, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  if (isLoading) {
    return <LoadingEl />;
  }

  return (
    <>
      {!isAuthenticated && (
        <div className="md:flex hidden justify-end">
          <button
            onClick={() => loginWithRedirect()}
            className="mr-4 hover:text-white border-[1.5px] dark:dashboardBtn border-black text-black dark:text-white dark:border-white dashboardBtn dark:hover:border-[#8A64DF] hover:border-[#8A64DF]"
          >
            SignIn
          </button>
          <button
            onClick={() => loginWithRedirect({ login_hint: "signup" })}
            className="logoutBtn dark:hover:logoutBtn text-white hover:bg-black dark:hover:bg-white dark:hover:text-[#0B101C]"
          >
            SignUp
          </button>
        </div>
      )}
      {isAuthenticated && (
        <div className="md:flex hidden justify-end">
          <a
            href="https://askzuro.com/my-account/"
            className="mr-4 hover:text-white border-[1.5px] dark:dashboardBtn border-black text-black dark:text-white dark:border-white dashboardBtn dark:hover:border-[#8A64DF] hover:border-[#8A64DF]"
          >
            Dashboard
          </a>
          <button
            onClick={() => logout({ returnTo: window.location.origin })}
            className="logoutBtn dark:hover:logoutBtn text-white hover:bg-black dark:hover:bg-white dark:hover:text-[#0B101C]"
          >
            Logout
          </button>
        </div>
      )}
    </>
  );
};

export default LoginBtn;
