import React from "react";

const ErrorsComponent = ({ data, darkToggle, errorSyntax }) => {
  return (
    <>
      {data.errorEl && (
        <div className="flex items-end justify-end mt-4">
          <div style={{ color: "red", fontSize: "16px", marginLeft: "2rem" }}>
            There seems to be an error - we are looking in and will fix it ASAP
          </div>
          <img
            className="w-[46px] h-[46px] rounded-lg ml-[12px] mr-[8px]"
            src={`/assets/images/${
              darkToggle ? "darkBorder" : "lightBorder"
            }.png`}
            alt="worker"
          />
        </div>
      )}
      {errorSyntax[data.highchartId] && (
        <div className="flex items-end justify-end mt-4">
          <div style={{ color: "red", fontSize: "16px", marginLeft: "2rem" }}>
            There seems to be an error - Please retry again
          </div>
          <img
            className="w-[46px] h-[46px] rounded-lg ml-[12px] mr-[8px]"
            src={`/assets/images/${
              darkToggle ? "darkBorder" : "lightBorder"
            }.png`}
            alt="worker"
          />
        </div>
      )}
    </>
  );
};

export default ErrorsComponent;
