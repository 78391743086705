import { useState,  } from "react";
import LoginBtn from "./LoginBtn";


const Header = ({ darkToggle, setDarkToggle }) => {
  const [toggle, setToggle] = useState(true);
  const [open, setOpen] = useState(false);



  return (
    <>
      <header className="bg-white h-[80px] dark:bg-gray-900 mb-[40px]">
        <nav className="dashNav mx-auto md:flex hidden justify-between items-center 2xl:py-4 py-3 px-[40px]">
          <div className="flex items-center">
            {/*   Switch Container */}
            <label className="toggle-switch mr-2" >
              <input type="checkbox" />
              <div className="toggle-switch-background" onClick={() => {
                setToggle(!toggle);
                setDarkToggle(!darkToggle);
              }}>
                <div className="toggle-switch-handle"></div>
              </div>
            </label>
            <span className="ml-2" >
              {darkToggle ? <>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.34323 4.92892L4.92923 3.51492C4.53923 3.12442 3.90523 3.12442 3.51523 3.51492C3.12473 3.90542 3.12473 4.53842 3.51523 4.92892L4.92923 6.34292C5.12423 6.53842 5.38023 6.63592 5.63623 6.63592C5.89223 6.63592 6.14823 6.53842 6.34323 6.34292C6.73373 5.95242 6.73373 5.31942 6.34323 4.92892Z" />
                  <path d="M3 11.0001H1C0.448 11.0001 0 11.4481 0 12.0001C0 12.5521 0.448 13.0001 1 13.0001H3C3.552 13.0001 4 12.5521 4 12.0001C4 11.4481 3.552 11.0001 3 11.0001Z" />
                  <path d="M6.34323 17.657C5.95323 17.2665 5.31923 17.2665 4.92923 17.657L3.51523 19.071C3.12473 19.4615 3.12473 20.0945 3.51523 20.485C3.71023 20.6805 3.96623 20.778 4.22223 20.778C4.47823 20.778 4.73423 20.6805 4.92923 20.485L6.34323 19.071C6.73373 18.6805 6.73373 18.0475 6.34323 17.657Z" />
                  <path d="M11.9998 20.0001C11.4478 20.0001 10.9998 20.4481 10.9998 21.0001V23.0001C10.9998 23.5521 11.4478 24.0001 11.9998 24.0001C12.5518 24.0001 12.9998 23.5521 12.9998 23.0001V21.0001C12.9998 20.4481 12.5518 20.0001 11.9998 20.0001Z" />
                  <path d="M20.4851 19.071L19.0711 17.657C18.6811 17.2665 18.0471 17.2665 17.6571 17.657C17.2666 18.0475 17.2666 18.6805 17.6571 19.071L19.0711 20.485C19.2661 20.6805 19.5221 20.778 19.7781 20.778C20.0341 20.778 20.2901 20.6805 20.4851 20.485C20.8756 20.0945 20.8756 19.4615 20.4851 19.071Z" />
                  <path d="M22.9999 11.0001H20.9999C20.4479 11.0001 19.9999 11.4481 19.9999 12.0001C19.9999 12.5521 20.4479 13.0001 20.9999 13.0001H22.9999C23.5519 13.0001 23.9999 12.5521 23.9999 12.0001C23.9999 11.4481 23.5519 11.0001 22.9999 11.0001Z" />
                  <path d="M20.4851 3.51492C20.0951 3.12442 19.4611 3.12442 19.0711 3.51492L17.6571 4.92892C17.2666 5.31942 17.2666 5.95242 17.6571 6.34292C17.8521 6.53842 18.1081 6.63592 18.3641 6.63592C18.6201 6.63592 18.8761 6.53842 19.0711 6.34292L20.4851 4.92892C20.8756 4.53842 20.8756 3.90542 20.4851 3.51492Z" />
                  <path d="M11.9998 0C11.4478 0 10.9998 0.448 10.9998 1V3C10.9998 3.552 11.4478 4 11.9998 4C12.5518 4 12.9998 3.552 12.9998 3V1C12.9998 0.448 12.5518 0 11.9998 0Z" />
                  <path d="M12.0002 5.49991C8.41619 5.49991 5.50018 8.41591 5.50018 11.9999C5.50018 15.5839 8.41619 18.4999 12.0002 18.4999C15.5842 18.4999 18.5002 15.5839 18.5002 11.9999C18.5002 8.41591 15.5842 5.49991 12.0002 5.49991ZM12.0002 16.4999C9.51919 16.4999 7.50018 14.4809 7.50018 11.9999C7.50018 9.51891 9.51919 7.49991 12.0002 7.49991C14.4812 7.49991 16.5002 9.51891 16.5002 11.9999C16.5002 14.4809 14.4812 16.4999 12.0002 16.4999Z" />
                </svg>
              </> : <>
                <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_858_222)">
                    <path d="M13.008 24C10.416 24 7.824 23.136 5.712 21.528C2.88 19.416 1.152 16.08 1.008 12.504C0.768004 8.18397 3.12 3.98397 6.816 1.75197C8.928 0.503971 11.28 -0.120029 13.752 0.0239713C14.376 0.0239713 15 0.527971 15.12 1.00797C15.36 1.63197 15.24 2.25597 14.76 2.73597C12.792 4.70397 12.048 7.43997 12.528 10.152C13.392 14.112 17.112 16.824 21.192 16.464C21.816 16.464 22.44 16.704 22.8 17.328C23.16 17.832 23.04 18.576 22.68 19.056C20.952 21.408 18.36 23.136 15.504 23.76C14.736 23.88 13.872 24 13.008 24ZM11.784 2.61597C10.536 2.73597 9.312 3.23997 8.064 3.98397C5.088 5.71197 3.36 9.04797 3.48 12.384C3.6 15.216 4.968 17.832 7.2 19.56C9.432 21.288 12.264 21.912 15 21.288C16.728 20.928 18.216 20.184 19.44 18.936C15 18.576 11.16 15.216 10.056 10.656C9.552 7.91997 10.176 4.96797 11.784 2.61597Z" fill="#8A64DF" />
                  </g>
                  <defs>
                    <clipPath id="clip0_858_222">
                      <rect width="24" height="24" fill="#8A64DF" />
                    </clipPath>
                  </defs>
                </svg></>}
            </span>
          </div>
          <div className="flex items-center">
            {darkToggle ?
              <img className="w-[128px]" src="https://askzuro.com/wp-content/uploads/2023/03/zuro_header_logo.svg" alt="zuro logo" />
              :
              <img className="w-[128px]" src="/assets/images/darkLogo.png" alt="zuro logo" />
            }
          </div>
          <LoginBtn />
        </nav>
        <nav className="mobileNav mx-auto flex md:hidden justify-between items-center 2xl:py-4 py-3 px-12">
          <div className="flex items-center">
            {darkToggle ?
              <img className="w-[128px]" src="https://askzuro.com/wp-content/uploads/2023/03/zuro_header_logo.svg" alt="zuro logo" />
              :
              <img className="w-[128px]" src="/assets/images/darkLogo.png" alt="zuro logo" />
            }
          </div>
          <div onClick={() => setOpen(!open)} className="text-3xl absolute right-8 top-5 cursor-pointer md:hidden">
            <i className={`fa-solid fa-${open ? 'x' : 'bars'} text-black dark:text-white`}></i>
          </div>
          <div className={`md:z-auto z-[1] left-0 w-full md:w-auto px-12 transition-all border-b border-gray-200 dark:border-gray-700 duration-500 ease-in md:flex md:pb-0 pb-12 absolute md:static bg-white dark:bg-gray-900 md:items-center text-md ${open ? 'top-20' : 'top-[-490px] md:opacity-100 opacity-0'}`}>
            <div className="flex itens-center justify-center text-center">
            <LoginBtn />
              <div className="flex items-center mb-3">
                <label className="toggle-switch mr-2" onClick={() => {
                  setToggle(!toggle);
                  setDarkToggle(!darkToggle);
                }}>
                  <input type="checkbox" />
                  <div className="toggle-switch-background">
                    <div className="toggle-switch-handle"></div>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  )
}
export default Header