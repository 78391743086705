import React from "react";

const SourcesComponent = ({data, darkToggle, selectedResponse}) => {
  return (
    <>
      {data.sources && data.sources.length > 0 && (
        <>
          <div className="flex items-end justify-end">
            <div
              className={`${
                data.sources.length <= 2
                  ? "flex items-end justify-end"
                  : "grid lg:grid-cols-4 grid-cols-2 gap-2 direction-rtl"
              } w-[300px]`}
            >
              {data.sources.map((item, index) => {
                return (
                  <a
                    key={index}
                    href={item}
                    rel="noreferrer"
                    target="_blank"
                    className={`${
                      data.sources.length <= 2 ? "mr-2" : "direction-ltr"
                    } mt-4`}
                  >
                    <div
                      className={`flex items-end justify-end flex-col rounded-lg max-w-xl`}
                    >
                      <img
                        className="object-cover w-[65px] rounded-lg"
                        src="/assets/images/pdf.png"
                        alt="pdf file"
                      />
                    </div>
                  </a>
                );
              })}
            </div>
            <img
              className="rounded-lg w-[46px] h-[46px] ml-[12px] mr-[8px]"
              src={`/assets/images/${
                darkToggle ? "darkBorder" : "lightBorder"
              }.png`}
              alt="worker"
            />
          </div>

          <div className="flex items-end justify-end mt-4">
            <div className="rounded-tl-lg rounded-tr-lg rounded-bl-lg max-w-xl bg-gray-300">
              <div className="rounded-tl-lg rounded-tr-lg rounded-br-lg max-w-xl bg-[#EDEFF1] ml-[12px] dark:bg-[#252F46]">
                <p className="font-poppins 2xl:text-[15px] text-black text-sm p-4 dark:text-white">
                  {selectedResponse}
                </p>
              </div>
            </div>
            <img
              className="rounded-lg w-[46px] h-[46px] ml-[12px] mr-[8px]"
              src={`/assets/images/${
                darkToggle ? "darkBorder" : "lightBorder"
              }.png`}
              alt="worker"
            />
          </div>
        </>
      )}
    </>
  );
};

export default SourcesComponent;
