import { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Accordion, AccordionHeader, AccordionBody, Typography } from "@material-tailwind/react";

function Icon({ id, open }) {
    return (
        <svg className={`${id === open ? "rotate-180" : ""
            }`}
            width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9998 4.70571C12.0031 4.56814 11.9602 4.43305 11.8772 4.31922C11.7941 4.20538 11.675 4.11846 11.5364 4.07054C11.3978 4.02261 11.2467 4.01607 11.1039 4.05181C10.9612 4.08755 10.834 4.16381 10.7399 4.26998L7.0115 8.32087L3.28438 4.26998C3.22639 4.19654 3.15277 4.13503 3.06806 4.08931C2.98334 4.0436 2.88938 4.01465 2.79209 4.00429C2.6948 3.99393 2.59628 4.00238 2.50267 4.02911C2.40905 4.05584 2.32234 4.10027 2.24803 4.15962C2.17372 4.21897 2.11344 4.29197 2.07083 4.37403C2.02823 4.4561 2.0043 4.54547 2.00053 4.63654C1.99675 4.72762 2.01317 4.81844 2.04885 4.90332C2.08453 4.98819 2.13865 5.0653 2.20783 5.12981L6.47113 9.76771C6.53791 9.84054 6.62079 9.89903 6.7142 9.93913C6.80761 9.97923 6.90929 10 7.01216 10C7.11503 10 7.2167 9.97923 7.31011 9.93913C7.40352 9.89903 7.48649 9.84054 7.55326 9.76771L11.8207 5.12981C11.9325 5.01265 11.9959 4.86237 11.9998 4.70571Z" fill="#7D89A3" />
        </svg>
    );
}

const LeftSection = () => {
    const [open, setOpen] = useState(0);

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };

    return (
        <section className="pb-4 lg:w-1/5 lg:flex hidden flex-col transition ease-in-out delay-650">
            <div className=" flex justify-between flex-col h-full">
                <div className="font-poppins">
                    <div className="flex items-center 2xl:mb-[15px] mb-2">
                        <h3 className="text-black 2xl:text-[18px] text-[14px] dark:text-white font-semibold">How can I help you?</h3>
                    </div>
                    <Accordion icon={<Icon id={1} open={open} />} open={open === 1} className={`${open === 1 ? 'dark:bg-gray-900 dark:border-gray-900' : 'dark:bg-[#0B101C] dark:border-[#252F46]'} bg-white border-[#E5E5E5] border cursor-pointer px-2 rounded-[10px] mt-2 py-1`}>
                        <AccordionHeader onClick={() => handleOpen(1)} className="border-0">
                            <p className={`2xl:text-[0.875rem] lg:text-[0.675rem] text-xs ml-2 text-black  ${open === 1 ? "dark:text-white" : "dark:text-gray-700"}`} >Put your data to work for your company</p>
                        </AccordionHeader>
                        <AccordionBody className="2xl:text-[0.7rem] font-light text-xs text-black dark:text-white pt-0 pb-4">
                            <ul className="ulAccordion">
                                <li><Typography className="font-poppins 2xl:text-[14px] font-light text-xs text-black dark:text-white">Documents, PDFs, PPTs and other content sources</Typography></li>
                                <li><Typography className="font-poppins 2xl:text-[14px] mt-2 text-xs text-black dark:text-white">Websites, Code repositories, help center documents and other sites</Typography></li>
                                <li><Typography className="font-poppins 2xl:text-[14px] mt-2 text-xs text-black dark:text-white">Linkedin, Twitter and other public social media</Typography></li>
                                <li><Typography className="font-poppins 2xl:text-[14px] mt-2 text-xs text-black dark:text-white">Airtable, PowerBI, Tableu and others data sources</Typography></li>
                                <li><Typography className="font-poppins 2xl:text-[14px] mt-2 text-xs text-black dark:text-white">Custom connectors built on demand</Typography></li>
                            </ul>
                        </AccordionBody>
                    </Accordion>
                    <Accordion icon={<Icon id={2} open={open} />} open={open === 2} className={`${open === 2 ? 'dark:bg-gray-900 dark:border-gray-900' : 'dark:bg-[#0B101C] dark:border-[#252F46]'} bg-white border-[#E5E5E5] border cursor-pointer px-2 rounded-[10px] mt-2 py-1`}>
                        <AccordionHeader onClick={() => handleOpen(2)} className="border-0">
                            <p className={`2xl:text-[0.875rem] lg:text-[0.675rem] text-xs ml-2 text-black  ${open === 2 ? "dark:text-white" : "dark:text-gray-700"}`}>Work together better as team</p>
                        </AccordionHeader>
                        <AccordionBody className="2xl:text-[0.7rem] font-light text-xs text-black dark:text-white pt-0 pb-4">
                            <ul className="ulAccordion">
                                <li><Typography className="font-poppins 2xl:text-[14px] text-xs text-black dark:text-white">Schedule a meeting with your internal team</Typography></li>
                                <li><Typography className="font-poppins 2xl:text-[14px] mt-2 text-xs text-black dark:text-white">Schedule a sales call with a prospect</Typography></li>
                                <li><Typography className="font-poppins 2xl:text-[14px] mt-2 text-xs text-black dark:text-white">Explain the company's XXX policy to your users</Typography></li>
                                <li><Typography className="font-poppins 2xl:text-[14px] mt-2  text-xs text-black dark:text-white">Airtable, PowerBI, Tableu and others data sources</Typography></li>
                                <li><Typography className="font-poppins 2xl:text-[14px] mt-2 text-xs text-black dark:text-white">Provide information on demand to your team</Typography></li>
                            </ul>
                        </AccordionBody>
                    </Accordion>
                    <Accordion icon={<Icon id={3} open={open} />} open={open === 3} className={`${open === 3 ? 'dark:bg-gray-900 dark:border-gray-900' : 'dark:bg-[#0B101C] dark:border-[#252F46]'} bg-white border-[#E5E5E5] border cursor-pointer px-2 rounded-[10px] mt-2 py-1`}>
                        <AccordionHeader onClick={() => handleOpen(3)} className="border-0">
                            <p className={`2xl:text-[0.875rem] lg:text-[0.675rem] text-xs ml-2 text-black ${open === 3 ? "dark:text-white" : "dark:text-gray-700"}`} >Create on brand content 10x faster</p>
                        </AccordionHeader>
                        <AccordionBody className="2xl:text-[0.7rem] font-light text-xs text-black dark:text-white pt-0 pb-4">
                            <ul className="ulAccordion">
                                <li><Typography className="font-poppins 2xl:text-[14px] font-light text-xs text-black dark:text-white">Write an topic outline for a blog post</Typography></li>
                                <li><Typography className="font-poppins 2xl:text-[14px] mt-2 text-xs text-black dark:text-white">Get me email subject lines for a campaign</Typography></li>
                                <li><Typography className="font-poppins 2xl:text-[14px] mt-2 text-xs text-black dark:text-white">Give me a tweet about XXX</Typography></li>
                                <li><Typography className="font-poppins 2xl:text-[14px] mt-2 text-xs text-black dark:text-white">Write a LinkedIn post for me</Typography></li>
                                <li><Typography className="font-poppins 2xl:text-[14px] mt-2 text-xs text-black dark:text-white">Write a blog post for my company</Typography></li>
                            </ul>
                        </AccordionBody>
                    </Accordion>
                    <Accordion icon={<Icon id={4} open={open} />} open={open === 4} className={`${open === 4 ? 'dark:bg-gray-900 dark:border-gray-900' : 'dark:bg-[#0B101C] dark:border-[#252F46]'} bg-white border-[#E5E5E5] border cursor-pointer px-2 rounded-[10px] mt-2 py-1`}>
                        <AccordionHeader onClick={() => handleOpen(4)} className="border-0">
                            <p className={`2xl:text-[0.875rem] lg:text-[0.675rem] text-xs ml-2 text-black ${open === 4 ? "dark:text-white" : "dark:text-gray-700"}`} >Automate everyday tasks</p>
                        </AccordionHeader>
                        <AccordionBody className="2xl:text-[0.7rem] font-light text-xs text-black dark:text-white pt-0 pb-4">
                            <ul className="ulAccordion">
                                <li><Typography className="font-poppins 2xl:text-[14px] font-light text-xs text-black dark:text-white">Reminders for tasks and meetings</Typography></li>
                                <li><Typography className="font-poppins 2xl:text-[14px] mt-2 text-xs text-black dark:text-white">Daily standups with your team</Typography></li>
                                <li><Typography className="font-poppins 2xl:text-[14px] mt-2 text-xs text-black dark:text-white">Send leads to your CRM directly</Typography></li>
                                <li><Typography className="font-poppins 2xl:text-[14px] mt-2 text-xs text-black dark:text-white">Visualize your data with charts, graphs and diagrams</Typography></li>
                                <li><Typography className="font-poppins 2xl:text-[14px] mt-2 text-xs text-black dark:text-white">More tasks added everyday</Typography></li>
                            </ul>
                        </AccordionBody>
                    </Accordion>
                </div>
                <div className="leftSection mt-2 2xl:mt-0">
                    <Carousel showThumbs={false} showArrows={false} showStatus={false}>
                        <div className="carousel-el 2xl:py-[10px] 2xl:px-4 px-2 2xl:text-[14px] lg:text-[0.675rem] text-xs] bg-white text-black dark:text-gray-700 dark:bg-gray-900 p-4 border border-[#E5E5E5] dark:border-gray-900">
                            <p>Zuro gets smarter as you use it, over time it will understand you and your needs much better</p>
                        </div>
                        <div className="carousel-el 2xl:text-[14px] lg:text-[0.675rem] text-xs bg-white text-black dark:text-gray-700 dark:bg-gray-900 p-4 border border-[#E5E5E5] dark:border-gray-900">
                            <p>The real power of Zuro opens up when you have your own data and tools connected to Zuro</p>
                        </div>
                        <div className="carousel-el 2xl:text-[14px] lg:text-[0.675rem] text-xs bg-white  text-black dark:text-gray-700 dark:bg-gray-900 p-4 border border-[#E5E5E5] dark:border-gray-900">
                            <p> We custom develop agents and data connectors.</p>
                        </div>
                    </Carousel>
                </div>
            </div>
        </section >
    )
}
export default LeftSection