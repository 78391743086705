export const TEAM_ID = "T01";

export const responses = [
    "You can explore these links for a deeper understanding of this concept.",
    "Take a look at these sources for a deeper understanding.",
    "You can find more information in the sources shared above.",
    "Dive deeper into this subject by exploring these resources.",
    "In case you're interested in learning more, these sources can provide additional insights.",
    "Here are some links in case you want to go deeper."
]
