import React from 'react'

const ConversationControls = ({ darkToggle, toggleOne, setToggleOne, toggleTwo, setToggleTwo, toggleThree, setToggleThree, newConversation }) => {


    return (
        <>
            <div className="flex md:flex-row flex-col items-center justify-center md:justify-between items-center px-[20px] py-4 mx-[40px] bg-white rounded-[10px] dark:bg-gray-900">
                <div className="flex md:flex-row flex-col justify-center">
                   <div className="flex md:mb-0 mb-2 mr-[30px]">
                        <label className="toggle-switch mr-2">
                            <input type="checkbox" disabled={!toggleTwo || !toggleThree} />
                            <div className="toggle-switch-background" onClick={() => { if (toggleTwo || toggleThree) setToggleOne(!toggleOne); }}>
                                <div className="toggle-switch-handle"></div>
                            </div>
                        </label>
                        <p className={`mr-4 ${toggleOne ? 'dark:text-white text-black' : 'dark:text-gray-700 text-gray-400'} text-md`}>Search the web</p>
                    </div>
                    <div className="flex md:mb-0 mb-2 mr-[30px]">
                        <label className="toggle-switch mr-2">
                            <input type="checkbox" disabled={!toggleOne || !toggleThree} />
                            <div className="toggle-switch-background" onClick={() => { if (toggleOne || toggleThree) setToggleTwo(!toggleTwo); }}> 
                                <div className="toggle-switch-handle"></div>
                            </div>
                        </label>
                        <p className={` ${toggleTwo ? 'dark:text-white text-black' : 'dark:text-gray-700 text-gray-400'} text-md`}>Search the documents</p>
                    </div>
                    <div className="flex md:mb-0 mb-2 mr-[30px]">
                        <label className="toggle-switch mr-2">
                            <input type="checkbox" disabled={!toggleOne || !toggleTwo} />
                            <div className="toggle-switch-background" onClick={() => { if (toggleOne || toggleTwo) setToggleThree(!toggleThree); }}> 
                                <div className="toggle-switch-handle"></div>
                            </div>
                        </label>
                        <p className={` ${toggleThree ? 'dark:text-white text-black' : 'dark:text-gray-700 text-gray-400'} text-md`}>Generate An Image</p>
                    </div>
                </div>
                <div>
                    <button onClick={newConversation} style={{ lineHeight: "26px" }} className="mt-2 lg:mt-0 flex items-center text-md hover:text-white border-[2px] dark:dashboardBtn border-black text-black dark:text-white dark:border-white dashboardBtn dark:hover:border-[#8A64DF] hover:border-[#8A64DF]">
                        <span className="mr-2 plusSvg">
                            <svg className="" width="16" height="16" viewBox="0 0 16 16" fill={`${darkToggle ? 'white' : 'black'}`} xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.208 16V9.792H0V6.176H6.208V0H9.824V6.176H16V9.792H9.824V16H6.208Z" />
                            </svg>
                        </span>
                        <span className="xl:flex hidden">New Conversation</span>
                    </button>
                </div>
            </div>
        </>
    )
}

export default ConversationControls