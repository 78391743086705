import { generateImage } from "../api/dalleService";
import { chatapi } from "../api/openaiService";
import { searchDocument } from "../api/searchDocumentService";
import { searchInternet } from "../api/searchInternetService";
import mermaid from "mermaid";
import { checkIfHighchart, extractHighchart } from "./highchart";
import { checkIfMermaid, extractMermaidCode } from "./mermaid";

export const getRequestType = (input) => {
    const inputLowerCase = input.toLowerCase();

    if (checkIfMermaid(inputLowerCase)) return "mermaid";
    else if (checkIfHighchart(inputLowerCase)) return "highcharts";
    return "";
};




// When the user send a message we display it directly in the screen, and wait for the backend response
export const parseAndSetUserMessage = (
    data,
    setChatLog,
    input,
    setFormData,
    formData
) => {
    let element = input;
    let reqType, userMessage;

    if (data === "submit") {
        reqType = getRequestType(input);
        userMessage = { role: "user", content: `${input}` };
    } else {
        reqType = getRequestType(data);
        userMessage = { role: "user", content: `${data}` };
    }
    setChatLog((chatLog) => [...chatLog, userMessage]);
    setFormData({ ...formData, input: "" });

    return { element, reqType };
};

// This function send the user request to the backend and returns the response and store it the varisable result
export const handleRequest = async (
    data,
    toggleOne,
    toggleTwo,
    toggleThree,
    element,
    reqType,
    conversationId,
    selectRandomResponse,
    userId
) => {
     let result;
    if (!toggleTwo) {
        result = await searchDocument(element);
        selectRandomResponse();
    } else if (!toggleOne) {
        result = await searchInternet(element, conversationId, userId);
    } else if (!toggleThree) {
        result = await generateImage(element, userId);
    } else {
        if (data !== "submit") {
            result = await chatapi(data, "", conversationId, userId);
        } else {
            result = await chatapi(element, reqType, conversationId, userId);
        }
    }
    return result;
};

export const parseAndSetBotResponse = async (
    result,
    element,
    setChatLog,
    errorSyntax,
    setIsLoading
) => {
    let mermaidCode, mermaidId, highchartId, highchartCode, reqType;
    let localMermaidError = false;
    let response = result.response;
    if (response) {
        reqType = getRequestType(element);
        if (reqType === "mermaid") {
            mermaidCode = extractMermaidCode(response);
            const handleMermaidParsing = async () => {
                try {
                    await mermaid.parse(mermaidCode);
                    localMermaidError = false;
                    mermaidId = mermaidCode ? `mermaid-${Date.now()}` : null;
                } catch (error) {
                    localMermaidError = true;
                    console.error("Error:", error);
                }
            };

            await handleMermaidParsing();
        } else if (reqType === "highcharts") {
            highchartId = `highchartId-${Date.now()}`;
            highchartCode = extractHighchart(response, highchartId);
        }
    }
    setChatLog((chatLog) => [
        ...chatLog,
        {
            role: "assistant",
            content: response,
            mermaidCode,
            imgUrl:  result.image_url,
            mermaidError: localMermaidError,
            mermaidId,
            highchartCode,
            highchartError: errorSyntax[highchartId],
            highchartId,
            sources: result.sources,
        },
    ]);
    setIsLoading(false);
};
