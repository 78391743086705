import axios from "axios";
import { TEAM_ID } from "../config/constants";

export const chatapi = async (prompt, reqType, conversationId, userId) => {
    let response;
    if (reqType === "mermaid") {
        response = await axios.post(
            process.env.REACT_APP_BACKEND_URL,
            {
                user_id: userId,
                team_id:TEAM_ID,
                conversation_id: conversationId,
                prompt: prompt +
                    ` Please provide me with a mermaid.js diagram written as text not code, without backticks or explanations or a title. 
              The diagram should be supported by mermaid.js.
               The diagram should be formatted correctly and eligible to be tested on mermaidjs. 
               The title should only be the diagram type using the format that mermaid uses. 
              and should represent relationships between entities rather than fields within a single entity.
              Here are some examples you need to respect and take in consideration:\n
              1. classDiagram / class diagram (Do not use interface only class):
              classDiagram
                Animal <|-- Duck
                Animal <|-- Fish
                Animal <|-- Zebra
                Animal : +int age
                Animal : +String gender
                Animal: +isMammal()
                Animal: +mate()
                class Duck{
                  +String beakColor
                  +swim()
                  +quack()
                }
                class Fish{
                  -int sizeInFeet
                  -canEat()
                }
                class Zebra{
                  +bool is_wild
                  +run()
                } \n
              2. sequenceDiagram/sequence diagram:
                sequenceDiagram
                  Alice->>+John: Hello John, how are you?
                  Alice->>+John: John, can you hear me?
                  John-->>-Alice: Hi Alice, I can hear you!
                  John-->>-Alice: I feel great!\n
              3. flowChart:
                flowchart TD
                  A[Christmas] -->|Get money| B(Go shopping)
                  B --> C{Let me think}
                  C -->|One| D[Laptop]
                  C -->|Two| E[iPhone]
                  C -->|Three| F[fa:fa-car Car]\n
              4. erDiagram/ er diagram:
                  erDiagram
                    CUSTOMER }|..|{ DELIVERY-ADDRESS : has
                    CUSTOMER ||--o{ ORDER : places
                    CUSTOMER ||--o{ INVOICE : "liable for"
                    DELIVERY-ADDRESS ||--o{ ORDER : receives
                    INVOICE ||--|{ ORDER : covers
                    ORDER ||--|{ ORDER-ITEM : includes
                    PRODUCT-CATEGORY ||--|{ PRODUCT : contains
                    PRODUCT ||--o{ ORDER-ITEM : "ordered in"\n
              5. stateDiagram/state diagram:
                  stateDiagram-v2
                    [*] --> Still
                    Still --> [*]
                    Still --> Moving
                    Moving --> Still
                    Moving --> Crash
                    Crash --> [*]
                    `,
            });
    } else if (reqType === "highcharts") {
        const ex = ` Please write JavaScript code for a Highcharts chart with a height of 400 pixels, the type should be with the height here is an example [{chart:{height:400, type:''}}], using 'highchartId' as the container ID.
         Exclude any explanations or backticks. Put the appropriate chart title. 
         Format the data as a CSV string with semicolons and newline characters as separators, enclosed in double quotes, like this example: "slice;val\n"2018";75\n"2019";50\n"2020";90\n"2021";60". Enclose the code in <script> tags. (It is just an example) [data: { "csv": \"\"slice\";\"val\"\n\"Not Pacman\";24\n\"Pacman\";76\"}]. 
         Everything should be pure text with double quotes.
         Enclose the code in <script> tags. the following is an example of how the highchartjs should look like:
         <script>
         Highcharts.chart('highchartId', {
             chart: {
                 "height": 400,
                 "type": "column"
         },
         credits: {
            enabled: false
        },
             title: {
                 text: "Weather in Morocco in the last 6 years"
         },
         
             xAxis: {
                 categories: ['2015', '2016', '2017', '2018', '2019', '2020']
         },
             yAxis: {
                 title: {
                     text: "Temperature (°C)"
                 }
         },
         data: {
          csv: "Year,Minimum temperature,Maximum temperature\n2015,11,26\n2016,12,28\n2017,8,24\n2018,14,30\n2019,7,25\n2020,10,27"
      },
             plotOptions: {
                 column: {
                     pointPadding: 0.2,
                     borderWidth: 0
                 }
         },
             series: [{
                 name: 'Minimum temperature',
                 data: [11, 12, 8, 14, 7, 10]
         }, {
                 name: 'Maximum temperature',
                 data: [26, 28, 24, 30, 25, 27]
         }]
         });
         </script>
         <script>
         Highcharts.chart('highchartId', {
             chart: {
                 height: 400,
                 type: 'line'
             },
             credits: {
                enabled: false
            },
             title: {
                 text: 'Death in Morocco in the Last 6 Years'
             },
            
             xAxis: {
                 categories: ['2015', '2016', '2017', '2018', '2019', '2020']
             },
             yAxis: {
                 title: {
                     text: 'Number of Deaths'
                 }
             },
             data: {
              csv: "Year,Deaths\n2015,26872\n2016,28530\n2017,29916\n2018,32453\n2019,31386\n2020,33266"
          },
             plotOptions: {
                 line: {
                     dataLabels: {
                         enabled: true
                     },
                     enableMouseTracking: false
                 }
             },
             series: [{
                 name: 'Deaths',
                 data: [26872, 28530, 29916, 32453, 31386, 33266]
             }]
         });
         </script>
         `
        response = await axios.post(process.env.REACT_APP_BACKEND_URL, {
            conversation_id:conversationId,
            user_id:userId,
            team_id:TEAM_ID,
            prompt: prompt + ex
        });
    }
    else {
        response = await axios.post(process.env.REACT_APP_BACKEND_URL, {
            conversation_id:conversationId,
            user_id:userId,
            team_id:TEAM_ID,
            prompt
        });
        console.log(response)

    }
    return response.data;
}