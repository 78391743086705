import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./assets/css/output.css";
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from "@material-tailwind/react";
import { Auth0Provider } from '@auth0/auth0-react';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <HelmetProvider>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
          redirectUri={window.location.origin}>
          <App />
        </Auth0Provider>
      </HelmetProvider>
    </ThemeProvider>
  </React.StrictMode>
);


reportWebVitals();
