import mermaid from "mermaid";
import { useEffect } from "react";

const MermaidComponent = ({darkToggle, chatLog, mermaidCode, mermaidId, mermaidError }) => {


  useEffect(() => {
    if (chatLog.length > 0) {
      const lastItem = chatLog[chatLog.length - 1];
      if (lastItem.mermaidCode && lastItem.mermaidId) {
        try {
          mermaid.parse(lastItem.mermaidCode); // Check if the syntax is correct
          mermaid.initialize({
            startOnLoad: true,
          });
          mermaid.contentLoaded();
        } catch (error) {
          // Handle the syntax error
          console.error('Mermaid syntax error:', error);
        }
      }
    }
  }, [chatLog]);

  return (
    <>
      {mermaidCode && !mermaidError &&
        <div className="flex items-end justify-end mt-4">
          <div className="rounded-tl-lg rounded-tr-lg rounded-bl-lg w-[65%] bg-white">
            {mermaidId && <div className="mermaid" id={mermaidId}
              style={{ width: "100%", borderRadius: "10px" }} >{mermaidCode}</div>}
          </div>
          <img className="rounded-lg w-[46px] h-[46px] ml-[12px] mr-[8px]"
            src={`/assets/images/${darkToggle ? 'darkBorder' : 'lightBorder'}.png`}
            alt="worker" />
        </div>
      }
      {mermaidError && <div className="flex items-end justify-end mt-4">
        <div className="rounded-tl-lg rounded-tr-lg rounded-bl-lg max-w-xl bgGradientChat">
          <pre style={{ whiteSpace: 'pre-wrap', fontFamily: 'Poppins' }} className="2xl:text-[15px]  text-sm font-normal text-white px-4 py-2">{mermaidCode}</pre>
        </div>
        <img className="rounded-lg w-[46px] h-[46px] ml-[12px] mr-[8px]"
          src={`/assets/images/${darkToggle ? 'darkBorder' : 'lightBorder'}.png`}
          alt="worker" />
      </div>}
    </>
  )
}

export default MermaidComponent