import { handleRequest, parseAndSetBotResponse, parseAndSetUserMessage } from '../../functions/submit';
import { useAuth0 } from "@auth0/auth0-react";
import {responses} from "../../config/constants";
import Modal from '../layouts/Modal';
import { useState } from 'react';


const ChatForm = ({conversationId, setChatLog, toggleOne, toggleTwo, toggleThree, setIsLoading, formData, setFormData, setSelectedResponse, errorSyntax}) => {
    const { input } = formData; 
    const [openModal, setOpenModal] = useState(false);
    const { user } = useAuth0();

    const selectRandomResponse = () => {
        const randomIndex = Math.floor(Math.random() * responses.length);
        setSelectedResponse(responses[randomIndex]);
    };
    
    const handleSubmit = async (data, e) => {
        e && e.preventDefault();
        if(!user){
            setOpenModal(true);
        } else {
            setIsLoading(true);
        const { element, reqType } = parseAndSetUserMessage(data, setChatLog, input, setFormData, formData);
    
        try {
            const result = await handleRequest(data, toggleOne, toggleTwo, toggleThree, element, reqType, conversationId, selectRandomResponse, user.sub);
            await parseAndSetBotResponse(result, element, setChatLog, errorSyntax, setIsLoading);
        } catch (error) {
            setChatLog(chatLog => [...chatLog, {
                role: "assistant",
                errorEl: true
            }]);
            setIsLoading(false);
            console.log(error);
        }
        }
    };


  return (
    <div className="mt-4">
    <form className="font-avenir-regular relative" onSubmit={(e) => handleSubmit("submit", e)}>
        <input placeholder="Ask your question here" name="input"
            className="dark:focus:placeholder-opacity-0 2xl:text-md text-sm placeholder-[#6A6A6D] w-full px-[20px] py-[16px] border border-gray-400 outline-0 rounded-[14px] resize-none dark:bg-[#131C31] dark:placeholder-[#7D89A3] dark:text-white dark:border-[#252F46]"
            value={input}
            autoComplete="off"
            onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
        />
        <button type="submit" className="right-[20px] 2xl:top-[12px] top-[8px] 2xl:bottom-[12px] bottom-[8px] flex items-center justify-center absolute"
        ><svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="30" height="30" rx="15" fill="#8A64DF" />
                <g clipPath="url(#clip0_543_565)">
                    <path d="M8.1915 21.3611C8.07646 21.8897 8.48233 22.1135 8.85894 21.9447L21.5871 15.4181H21.5884C21.7402 15.3325 21.8284 15.1782 21.8284 15.0002C21.8284 14.822 21.7402 14.6676 21.5884 14.582H21.5871L8.85894 8.05537C8.48233 7.8865 8.07646 8.11035 8.1915 8.63901C8.19928 8.67482 8.95176 12.0267 9.36089 13.8496L16.0052 15.0001L9.36089 16.1505C8.95176 17.9733 8.19925 21.3252 8.1915 21.3611Z" fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_543_565">
                        <rect width="14" height="14" fill="white" transform="translate(8 8)" />
                    </clipPath>
                </defs>
            </svg>
        </button>
    </form>
    <Modal openModal={openModal} setOpenModal={setOpenModal} />
</div>
  )
}

export default ChatForm