import axios from "axios";
import { TEAM_ID } from "../config/constants";


export const searchInternet = async (prompt, conversationId, userId) => {
    const response = await axios.post(process.env.REACT_APP_WEB_URL, {
        user_id: userId,
        team_id:TEAM_ID,
        conversation_id: conversationId,
        prompt
    });
    return response.data;
}