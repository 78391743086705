import axios from "axios";
import { TEAM_ID } from "../config/constants";


export const generateImage= async (prompt, userId) => {
    const response = await axios.post(process.env.REACT_APP_DALLE, {
        user_id: userId,
        team_id:TEAM_ID,
        prompt});
    return response.data;
}